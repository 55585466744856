import { Controller } from "@hotwired/stimulus"
import ScrollReveal from "scrollreveal";

// Connects to data-controller="about-animation"
export default class extends Controller {
  connect() {

    this.firstAni();
  }


  firstAni() {
    ScrollReveal().reveal(".s3", { delay: 400, easing: 'ease-in' });
    ScrollReveal().reveal(".s4", { delay: 500, easing: 'ease-in' });
    ScrollReveal().reveal(".s5", { delay: 600, easing: 'ease-in' });
    ScrollReveal().reveal(".cros5", { delay: 600, easing: 'ease-in' });
    ScrollReveal().reveal(".s6", { delay: 700, easing: 'ease-in' });
    ScrollReveal().reveal(".cros6", { delay: 700, easing: 'ease-in' });
    ScrollReveal().reveal(".s7", { delay: 800, easing: 'ease-in' });
    ScrollReveal().reveal(".cros7", { delay: 800, easing: 'ease-in' });
    ScrollReveal().reveal(".s8", { delay: 900, easing: 'ease-in' });
    ScrollReveal().reveal(".cros8", { delay: 900, easing: 'ease-in' });
    ScrollReveal().reveal(".s9", { delay: 1000, easing: 'ease-in' });
    ScrollReveal().reveal(".cros9", { delay: 1000, easing: 'ease-in' });
    ScrollReveal().reveal(".cros10", { delay: 1100, easing: 'ease-in' });
    ScrollReveal().reveal(".danniani", { delay: 1000, easing: 'ease-in' });
    ScrollReveal().reveal(".s10", { delay: 1100, easing: 'ease-in' });
    ScrollReveal().reveal(".s11", { delay: 1200, easing: 'ease-in' });
    ScrollReveal().reveal(".us11", { delay: 1200, easing: 'ease-in' });
    ScrollReveal().reveal(".s12", { delay: 1300, easing: 'ease-in' });
    ScrollReveal().reveal(".s13", { delay: 1400, easing: 'ease-in' });
    ScrollReveal().reveal(".s14", { delay: 1500, easing: 'ease-in' });
    ScrollReveal().reveal(".s15", { delay: 1600, easing: 'ease-in' });
    ScrollReveal().reveal(".s16", { delay: 1700, easing: 'ease-in' });
    ScrollReveal().reveal(".s17", { delay: 1800, easing: 'ease-in' });
    ScrollReveal().reveal(".s18", { delay: 1900, easing: 'ease-in' });
    ScrollReveal().reveal(".s19", { delay: 2000, easing: 'ease-in' });
    ScrollReveal().reveal(".s20", { delay: 2100, easing: 'ease-in' });
    ScrollReveal().reveal(".s21", { delay: 2200, easing: 'ease-in' });
    ScrollReveal().reveal(".s22", { delay: 2300, easing: 'ease-in' });
    ScrollReveal().reveal(".s23", { delay: 2400, easing: 'ease-in' });
    ScrollReveal().reveal(".s24", { delay: 2500, easing: 'ease-in' });
    ScrollReveal().reveal(".s25", { delay: 2600, easing: 'ease-in' });
    ScrollReveal().reveal(".s26", { delay: 2000, easing: 'ease-in' });
    ScrollReveal().reveal(".s27", { delay: 2000, easing: 'ease-in' });
    ScrollReveal().reveal(".s28", { delay: 2000, easing: 'ease-in' });


  }






}
