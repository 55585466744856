import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="letters"
export default class extends Controller {
  static targets = ["slide"]

  connect() {
    if (window.matchMedia('(max-width: 767px)').matches) {

        return false;

    } else {
    this.letterAnimation()
    }
    // this.secondAnimation()
  }

  letterAnimation(){

    let config = {
      timeout: 2000,
      speed: 250,
      spread: 20
    }

    let nextSlide = 0,
      output = '',
      characters = [],
      maxChars,
      slides,
      slidesTexts;

    let callback = function () {
      if (currentSlide++ >= slides.length - 1) currentSlide = 0;
      setTimeout(formatText, config.timeout);
    }

    let formatText = function () {

      // calculate the next slide index
      nextSlide = (currentSlide + 1 >= slides.length) ? 0 : currentSlide + 1;

      // Fill the characters array if not already defined
      if (characters[currentSlide] == undefined) characters[currentSlide] = slides[currentSlide].innerHTML.split('');
      if (characters[nextSlide] == undefined) characters[nextSlide] = slides[nextSlide].innerHTML.split('');

      maxChars = Math.max(characters[currentSlide].length, characters[nextSlide].length);

      var fragment = document.createDocumentFragment();

      for (var i = 0; i < maxChars; ++i) {

        // create all elements

        var duration = config.speed / 1000;
        var delay = i * (config.speed / 1000 / config.spread);

        var front = document.createElement("span");
        front.className = "front";
        var styleFront = '-webkit-animation-delay: ' + delay + 's, ' + (delay + duration) + 's; -webkit-animation-duration: ' + duration + 's, 0.01s;' + '-moz-animation-delay: ' + delay + 's, ' + (delay + duration) + 's; -moz-animation-duration: ' + duration + 's, 0.01s;';

        front.setAttribute('style', styleFront);
        front.innerHTML = characters[currentSlide][i] || ' ';

        var back = document.createElement("span");
        back.className = "back";
        var styleBack = '-webkit-animation-delay: ' + (delay + duration) + 's, ' + (delay + duration) + 's; -webkit-animation-duration: ' + duration + 's, 0.01s;' + '-moz-animation-delay: ' + (delay + duration) + 's, ' + (delay + duration) + 's; -moz-animation-duration: ' + duration + 's, 0.01s';
        back.setAttribute('style', styleBack);
        back.innerHTML = characters[nextSlide][i] || ' ';

        var char = document.createElement("span");
        char.className = "char";
        char.appendChild(front)
        char.appendChild(back);
        fragment.appendChild(char);
      }

      setTimeout(function () {
        callback();
      }, maxChars * (config.speed / config.spread) + config.speed);

      slides[0].innerHTML = "";
      slides[0].appendChild(fragment);
    };

    slides = this.slideTargets;
    let currentSlide = 0;
    nextSlide = 1;
    formatText();
  }


  secondAnimation(){
    let words = this.slideTargets;
words.forEach((word) => {
  let letters = word.textContent.split("");
  word.textContent = "";
  letters.forEach((letter) => {
    let span = document.createElement("span");
    span.textContent = letter;
    span.className = "letter";
    word.append(span);
  });
});

let currentWordIndex = 0;
let maxWordIndex = words.length - 1;
words[currentWordIndex].style.opacity = "1";

let rotateText = () => {
  let currentWord = words[currentWordIndex];
  let nextWord =
    currentWordIndex === maxWordIndex ? words[0] : words[currentWordIndex + 1];
  // rotate out letters of current word
  Array.from(currentWord.children).forEach((letter, i) => {
    setTimeout(() => {
      letter.className = "letter out";
    }, i * 80);
  });
  // reveal and rotate in letters of next word
  nextWord.style.opacity = "1";
  Array.from(nextWord.children).forEach((letter, i) => {
    letter.className = "letter behind";
    setTimeout(() => {
      letter.className = "letter in";
    }, 340 + i * 80);
  });
  currentWordIndex =
    currentWordIndex === maxWordIndex ? 0 : currentWordIndex + 1;
};

rotateText();
setInterval(rotateText, 4000);
  }
}
