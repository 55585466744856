import { Controller } from "@hotwired/stimulus"
import Typed from "typed.js"

// Connects to data-controller="typer"
export default class extends Controller {
  static values = {
    translate: String
  }
  static targets = ["text"]

  connect() {
    this.translateToArray = this.translateValue.split(",")
    new Typed("#texta", {
      strings: this.translateToArray,
      typeSpeed: 100,
      backSpeed: 100,
      showCursor: false,
      smartBackspace: true,
      startDelay: 200,
      loop: true,
    });
  }
}
