import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="success"
export default class extends Controller {


  static values = {
    imagea: String,
    imageb: String,
    imagec: String,
    imaged: String,
    imagee: String,
    imagef: String,
    imageg: String,
    imageh: String,
    imagej: String,
    imagei: String
  }

  connect() {
    this.images = [this.imageaValue, this.imagebValue, this.imagecValue, this.imagedValue, this.imageeValue, this.imagefValue, this.imagegValue, this.imagehValue, this.imageiValue, this.imagejValue]
    this.i = 0;
    this.mouse = 0;

  }

  placeImage(x,y) {

    let nextImage = this.images[this.i]

    const img = document.createElement("img")
    img.setAttribute("src", nextImage)
    img.style.left = x + "px"
    img.style.top = y + "px"
    img.style.transform = "translate(-50%, -50%) scale(0.5) rotate(" +(Math.random() * 20) + "deg)"

    this.element.appendChild(img)

    this.i += 1

    if (this.i >= this.images.length) {
      this.i = 0
    }
  }


  activate(event) {
    event.preventDefault()
    this.mouse += 1
    if (this.mouse % 20 == 0) {
      this.placeImage(event.pageX, event.pageY)
    }

  }

  deactivate(event){
    event.preventDefault()
    this.placeImage(event.pageX, event.pageY)
  }

}
