import { Controller } from "@hotwired/stimulus"
import GlslCanvas from "glslCanvas";

// Connects to data-controller="about-pics"
export default class extends Controller {

  static targets = ["canvas", "canvasdeux", "canvastrois", "canvasquatro"]

  static values = {
    cluba: String,
    clubb: String,
    clubc: String,
    clubd: String,
  }

  connect() {
    this.animation();
    this.reSizer();
  }

  animation(){
    var canvas = this.canvasTarget;
    var sandbox = new GlslCanvas(canvas);

    var canvasDeux = this.canvasdeuxTarget;
    var sandboxDeux = new GlslCanvas(canvasDeux);

    var canvasTrois = this.canvastroisTarget;
    var sandboxTrois = new GlslCanvas(canvasTrois);

    var canvasQuatro = this.canvasquatroTarget;
    var sandboxFour = new GlslCanvas(canvasQuatro);

  const frag = `
#ifdef GL_ES
precision highp float;
#endif

uniform float u_time;
uniform vec2 resolution;
uniform vec2 mouse;
uniform vec3 spectrum;

uniform sampler2D image;


varying vec3 v_normal;
varying vec2 v_texcoord;

#define NUM_OCTAVES 5

float rand(vec2 n) {
    return fract(sin(dot(n, vec2(12.9898, 4.1414))) * 43758.5453);
}

float noise(vec2 p){
    vec2 ip = floor(p);
    vec2 u = fract(p);
    u = u*u*(3.0-2.0*u);

    float res = mix(
        mix(rand(ip),rand(ip+vec2(1.0,0.0)),u.x),
        mix(rand(ip+vec2(0.0,1.0)),rand(ip+vec2(1.0,1.0)),u.x),u.y);
    return res*res;
}

float fbm(vec2 x) {
    float v = 0.0;
    float a = 0.5;
    vec2 shift = vec2(100);
    // Rotate to reduce axial bias
    mat2 rot = mat2(cos(0.5), sin(0.5), -sin(0.5), cos(0.50));
    for (int i = 0; i < NUM_OCTAVES; ++i) {
        v += a * noise(x);
        x = rot * x * 2.0 + shift;
        a *= 0.5;
    }
    return v;
}


void main(void)
{
    vec2 uv =  v_texcoord;


    float strength = smoothstep(0.4, 0.04, uv.y);

    vec2 surface = strength * vec2(
    mix(-0.3, 0.3, fbm(5.0 * uv + 0.5 *  u_time)),
    mix(-0.3, 0.3, fbm(5.0 * uv + 0.5 *  u_time))
    );

    uv += refract(vec2(0.0, 0.0), surface, 1.0 / 1.333);


    vec4 color = texture2D(image,uv);

    gl_FragColor = color;
}
  `

  const frag2 = `
#ifdef GL_ES
precision highp float;
#endif

uniform float u_time;
uniform vec2 resolution;
uniform vec2 mouse;
uniform vec3 spectrum;

uniform sampler2D image;


varying vec3 v_normal;
varying vec2 v_texcoord;

#define NUM_OCTAVES 5

float rand(vec2 n) {
    return fract(sin(dot(n, vec2(12.9898, 4.1414))) * 43758.5453);
}

float noise(vec2 p){
    vec2 ip = floor(p);
    vec2 u = fract(p);
    u = u*u*(3.0-2.0*u);

    float res = mix(
        mix(rand(ip),rand(ip+vec2(1.0,0.0)),u.x),
        mix(rand(ip+vec2(0.0,1.0)),rand(ip+vec2(1.0,1.0)),u.x),u.y);
    return res*res;
}

float fbm(vec2 x) {
    float v = 0.0;
    float a = 0.5;
    vec2 shift = vec2(100);
    // Rotate to reduce axial bias
    mat2 rot = mat2(cos(0.5), sin(0.5), -sin(0.5), cos(0.50));
    for (int i = 0; i < NUM_OCTAVES; ++i) {
        v += a * noise(x);
        x = rot * x * 2.0 + shift;
        a *= 0.5;
    }
    return v;
}


void main(void)
{
    vec2 uv =  v_texcoord;


    float strength = smoothstep(0.8, 0.04, uv.y);

    vec2 surface = strength * vec2(
    mix(-0.3, 0.3, fbm(5.0 * uv + 0.5 *  u_time)),
    mix(-0.3, 0.3, fbm(5.0 * uv + 0.5 *  u_time))
    );

    uv += refract(vec2(0.0, 0.0), surface, 1.0 / 1.333);


    vec4 color = texture2D(image,uv);

    gl_FragColor = color;
}
  `

    sandbox.load(frag);
    sandboxDeux.load(frag2);
    sandboxTrois.load(frag)
    sandboxFour.load(frag2);

    sandbox.setUniform("image", `${this.clubaValue}`)
    sandboxDeux.setUniform("image", `${this.clubbValue}`)
    sandboxTrois.setUniform("image", `${this.clubcValue}`)
    sandboxFour.setUniform("image", `${this.clubdValue}`)
  }

  reSizer(){
    var canvas = this.canvasTarget;
    var canvasDeux = this.canvasdeuxTarget;
    var canvasTrois = this.canvastroisTarget;
    var canvasQuatro = this.canvasquatroTarget;
    var heightRatio = 1.4

    canvas.height = canvas.width * heightRatio;
    canvasDeux.height = canvasDeux.width * heightRatio;
    canvasTrois.height = canvasTrois.width * heightRatio;
    canvasQuatro.height = canvasQuatro.width * heightRatio;
  }
}
