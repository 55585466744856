import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="accordion"
export default class extends Controller {

  static targets = ["accordion"]

  connect() {

    this.startAccordion();
  }

  startAccordion(){
    for (let i = 0; i < this.accordionTargets.length; i++){
      this.accordionTargets[i].addEventListener("click", (e) => {
        e.currentTarget.classList.toggle("active")
        let panel = e.currentTarget.nextElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + "px";
        }
      })
    }
  }
}
