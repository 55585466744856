import { Controller } from "@hotwired/stimulus"
import { CountUp } from "countup.js";


// Connects to data-controller="counter"
export default class extends Controller {
  static targets = ["dataCount", "timeToStart", "testo", "fem", "co"]

  connect() {
    this.startingPoint = Math.round(Math.abs(this.timeToStartTarget.getBoundingClientRect().top));

    this.animationStart()

  }

  counterUp() {
    let counter = this.dataCountTargets;
    let a = Array.from(counter)

    a.map((item) => {
      let count = item.innerHTML;
      item.innerHTML = "";
      let countNumber = 0;



      function speedUp() {
       item.innerHTML = countNumber++;
        if (countNumber > count) {
          clearInterval(stop);
        }
      }

      let stop = setInterval(() => {
        speedUp();
      }, item.dataset.speed / count);
    });

  }

  animationStart(){
    // let currentScroll = Math.round(Math.abs(this.element.getBoundingClientRect().top))
    // let position_on_page = document.documentElement.scrollTop - this.startingPoint

    // console.log(position_on_page)
    // console.log("currentScroll", currentScroll, "triggerPoint", this.startingPoint)
    // if (this.currentScroll >= position_on_page){
    //   console.log("jackpot")
    // this.counterUp()
    // }
    let targetStart = this.timeToStartTarget.getBoundingClientRect()
    let scrollPosition = window.scrollY;
    let ranAlready = false

    // if(scrollPosition > targetStart.right && !ranAlready){
    const countUp = new CountUp(this.testoTarget, 5234, { duration: 5, enableScrollSpy: true, scrollSpyOnce: true});
      if (!countUp.error) {
        countUp.start();
        ranAlready = true

      } else {
        console.error(countUp.error);
      }

    const femo = new CountUp(this.femTarget, 63, { duration: 4, enableScrollSpy: true, scrollSpyOnce: true })
    if (!femo.error) {
      femo.start();
    } else {
      console.error(femo.error);
    }

    const carbo = new CountUp(this.coTarget, 4.4, { duration: 5, enableScrollSpy: true, scrollSpyOnce: true })
    if (!carbo.error) {
      carbo.start();
    } else {
      console.error(carbo.error);
    }






  }



}
