import { Controller } from "@hotwired/stimulus"
import ScrollReveal from "scrollreveal";

// Connects to data-controller="contact"
export default class extends Controller {
  connect() {
    this.animationStart();
  }

  animationStart() {
    ScrollReveal().reveal(".s1", { delay: 400, easing: 'ease-in' });
    ScrollReveal().reveal(".s2", { delay: 400, easing: 'ease-in' });
    ScrollReveal().reveal(".s3", { delay: 600, easing: 'ease-in' });
    ScrollReveal().reveal(".s4", { delay: 700, easing: 'ease-in' });
    ScrollReveal().reveal(".s5", { delay: 800, easing: 'ease-in' });
  }
}
