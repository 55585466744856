import { Controller } from "@hotwired/stimulus"
import ScrollReveal from "scrollreveal"


// Connects to data-controller="projectshow"
export default class extends Controller {

  connect() {
   this.startAnimation();
  }



  startAnimation(){
    ScrollReveal().reveal(".s3", { delay: 400, easing: 'ease-in' });
    ScrollReveal().reveal(".s4", { delay: 500, easing: 'ease-in' });
    ScrollReveal().reveal(".s5", { delay: 600, easing: 'ease-in' });
    ScrollReveal().reveal(".s6", { delay: 700, easing: 'ease-in' });
    ScrollReveal().reveal(".s7", { delay: 800, easing: 'ease-in' });
    ScrollReveal().reveal(".s8", { delay: 900, easing: 'ease-in' });
    ScrollReveal().reveal(".s9", { delay: 1000, easing: 'ease-in' });
    ScrollReveal().reveal(".s10", { delay: 1100, easing: 'ease-in' });
    ScrollReveal().reveal(".s11", { delay: 1200, easing: 'ease-in' });
    ScrollReveal().reveal(".s12", { delay: 1300, easing: 'ease-in' });
    ScrollReveal().reveal(".s13", { delay: 1400, easing: 'ease-in' });
    ScrollReveal().reveal(".s14", { delay: 1500, easing: 'ease-in' });
    ScrollReveal().reveal(".s15", { delay: 1600, easing: 'ease-in' });
    ScrollReveal().reveal(".s16", { delay: 1700, easing: 'ease-in' });
    ScrollReveal().reveal(".s17", { delay: 1800, easing: 'ease-in' });
    ScrollReveal().reveal(".s18", { delay: 1900, easing: 'ease-in' });
    ScrollReveal().reveal(".s19", { delay: 2000, easing: 'ease-in' });
    ScrollReveal().reveal(".s20", { delay: 2100, easing: 'ease-in' });
    ScrollReveal().reveal(".s21", { delay: 2200, easing: 'ease-in' });
  }
}
