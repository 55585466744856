import { Controller } from "@hotwired/stimulus"
import { useIntersection } from "stimulus-use";
import { Pixler } from "./pixler";


export default class extends Controller {
  static targets = ["canvas"]

  connect() {
    this.pixi = new Pixler(this.element)
    const [_observe, unobserve] = useIntersection(this, {threshold: 0.4})
    this.unobserve = unobserve
  }

  appear(entry, observer) {
    if (entry.isIntersecting) {
      setTimeout(() => {
        this.pixi.startAnimation().then(() => {
          setTimeout(() => {
            this.element.childNodes[0].style.display = "none"
          }, 300);
        })
      }, 2000);
    }

    this.unobserve()
  }

}
