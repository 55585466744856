import { Controller } from "@hotwired/stimulus"
import ScrollReveal from "scrollreveal";

// Connects to data-controller="body"
export default class extends Controller {
  static values = {
    page: String,
    image1: String,
    image2: String,
    image3: String,
    image4: String,
    image5: String,
    image6: String,
    color: String,
    }

  static targets = ["waypoint", "body", "chip", "acc", "endpoint", "menu", "listcat", "logo", "hyperlink", "modeButton", "myBar", "canvas"]
  connect() {

    this.color = this.colorValue;
    this.darkMode = localStorage.getItem("darkmode")
    this.openWindow()
  }

  activateMenu(e){
    if (this.menuTarget.classList.contains("inactive")) {
        this.menuTarget.classList.remove("inactive")
        e.currentTarget.innerText = "X"
      if(this.menuTarget.classList.contains("fadeOut")){
        this.menuTarget.classList.remove("fadeOut")
        this.menuTarget.classList.add("fadeIn")
      } else {
        this.menuTarget.classList.add("fadeIn")
      }

      this.listcatTargets.forEach((el, index) => {
          setTimeout(() => {
            el.style.opacity = 1;

          }, index * 200); // 500ms delay for each div

      })
     e.currentTarget.style.zIndex = "50001"
    } else {
      this.fadeOutDivs(e.currentTarget)
    }
  }

    async fadeOutDivs(target) {


     for (let i = this.listcatTargets.length - 1; i >= 0; i--) {
        await new Promise((resolve, reject) => {
         setTimeout(() => {
          this.listcatTargets[i].style.opacity = 0;

          resolve()

          }, i * 50);
        })
      }




     await new Promise((resolve, reject) => {

        if (this.menuTarget.classList.contains("fadeIn")) {
          this.menuTarget.classList.remove("fadeIn")
          this.menuTarget.classList.add("fadeOut")

          resolve()

        } else {
          this.menuTarget.classList.add("fadeOut")

          resolve()

        }

      }).then(
        this.closingMenu(target)
      )


  }


  closingMenu(target) {
    setTimeout(() => {
      target.innerText = "menu"
      this.menuTarget.classList.add("inactive")
    }, 100);


    }





   closeMenu(){

      this.menuTarget.classList.add("inactive")




  }

  makeDissapear() {

      this.menuTarget.classList.add("inactive")

  }


  openWindow(){
    document.addEventListener("visibilitychange", (event) => {
      if (document.visibilityState == "visible") {

        document.title = "Chip"
      } else {

        document.title = "Hey! Come Back ⚡️"
      }
    });
  }

  activateMenuRespo(e){
    e.currentTarget.classList.toggle("is-active")

    if(e.currentTarget.classList.contains("is-active")){
      this.menuTarget.classList.remove("inactive")
      this.listcatTargets.forEach((el, index) => {
        setTimeout(() => {
          el.style.opacity = 1;

        }, index * 200); // 500ms delay for each div

      })
    } else {
      this.menuTarget.classList.add("inactive")
    }


  }



  scrollIndicator(){
    var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    var scrolled = (winScroll / height) * 100;
    this.myBarTarget.style.width = scrolled + "%";
  }




}
