import { Controller } from "@hotwired/stimulus"
import ScrollReveal from "scrollreveal";
// Connects to data-controller="service-animation"
export default class extends Controller {


  connect() {
    this.firstAni()
  }


  firstAni() {
    ScrollReveal().reveal(".s3", { delay: 500, easing: 'ease-in' });
    ScrollReveal().reveal(".s4", { delay: 600, easing: 'ease-in' });
    ScrollReveal().reveal(".s5", { delay: 600, easing: 'ease-in' });
    ScrollReveal().reveal(".s6", { delay: 700, easing: 'ease-in' });
    ScrollReveal().reveal(".s7", { delay: 800, easing: 'ease-in' });
    ScrollReveal().reveal(".s8", { delay: 700, easing: 'ease-in' });
    ScrollReveal().reveal(".s9", { delay: 800, easing: 'ease-in' });
    ScrollReveal().reveal(".s10", { delay: 900, easing: 'ease-in' });
  }
}
