import { Controller } from "@hotwired/stimulus"
import { Pixler } from "./pixler"
import { useIntersection } from "stimulus-use"
// Connects to data-controller="projectpixel"
export default class extends Controller {

  connect() {
    this.pixi = new Pixler(this.element)
    const [_observe, unobserve] = useIntersection(this)
    this.unobserve = unobserve
  }

  appear(entry,observer) {
    if (entry.isIntersecting) {
      setTimeout(() => {
        this.pixi.startAnimation().then(() => {
          setTimeout(() => {
            this.element.childNodes[0].style.display = "none"
          }, 300);
        })
      }, 2000);
    }

    this.unobserve()
  }
}
