import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="player"
export default class extends Controller {

  static targets=["video"]

  connect() {

  }

  playVid(e){

  this.videoTarget.autoplay = true
  e.currentTarget.classList.add("fadeOut")
  }

}
