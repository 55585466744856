import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="projectpost"
export default class extends Controller {
  //static targets = ["input", "tagcontainer", "adam", "button", "userR", "uiinput", "taguicontainer", "uiDes", "tagtoolcontainer", "toolinput", "uxrange", "uxval", "uirange", "uival", "marval", "froval", "bacval", "toolz"]

  connect() {


    // this.hashtagArray = []
    // this.uitagArray = []
    // this.toolsTagArray = []
    // this.uxval = 0
    // this.preventForm()
    // this.createTags();
    // this.createUiTags()
    // this.createToolTags()
  }

  createTags() {
    const hashtagArray = []

    this.inputTarget.addEventListener('keyup', (event) => {
      if (event.which == 13 && this.inputTarget.value.length > 0) {
        var text = document.createTextNode(this.inputTarget.value);
        var p = document.createElement('p');
        this.tagcontainerTarget.appendChild(p);
        p.appendChild(text);
        p.classList.add('tag');
        this.hashtagArray.push(this.inputTarget.value)
        this.inputTarget.value = '';


        let deleteTags = document.querySelectorAll('.tag');

        for (let i = 0; i < deleteTags.length; i++) {
          deleteTags[i].addEventListener('click', () => {
            this.tagcontainerTarget.removeChild(deleteTags[i]);
          });
        }
      }
    });


  }


  createUiTags(){
    this.uiinputTarget.addEventListener('keyup', (event) => {
      if (event.which == 13 && this.uiinputTarget.value.length > 0) {
        var text = document.createTextNode(this.uiinputTarget.value);
        var p = document.createElement('p');
        this.taguicontainerTarget.appendChild(p);
        p.appendChild(text);
        p.classList.add('tag');
        this.uitagArray.push(this.uiinputTarget.value)
        this.uiinputTarget.value = '';


        let deleteTags = document.querySelectorAll('.tag');

        for (let i = 0; i < deleteTags.length; i++) {
          deleteTags[i].addEventListener('click', () => {
            this.taguicontainerTarget.removeChild(deleteTags[i]);
          });
        }
      }
    });

  }

  createToolTags(){
    this.toolinputTarget.addEventListener('keyup', (event) => {
      if (event.which == 13 && this.toolinputTarget.value.length > 0) {
        var text = document.createTextNode(this.toolinputTarget.value);
        var p = document.createElement('p');
        this.tagtoolcontainerTarget.appendChild(p);
        p.appendChild(text);
        p.classList.add('tag');
        this.toolsTagArray.push(this.toolinputTarget.value)
        this.toolinputTarget.value = '';


        let deleteTags = document.querySelectorAll('.tag');

        for (let i = 0; i < deleteTags.length; i++) {
          deleteTags[i].addEventListener('click', () => {
            this.tagtoolcontainerTarget.removeChild(deleteTags[i]);
          });
        }
      }
    });

  }

  preventForm() {
    this.adamTarget.onkeypress = function (e) {
      var key = e.charCode || e.keyCode || 0;
      if (key == 13) {

        e.preventDefault();
      }
    }
  }


  send(e) {

    this.userRTarget.value = this.hashtagArray
    this.uiDesTarget.value = this.uitagArray
    this.toolzTarget.value = this.toolsTagArray
  }

  showUxValue(e){
     this.uxvalTarget.innerText = e.target.value;
  }


  showUiValue(e){
    this.uivalTarget.innerText = e.target.value;
  }

  showMarValue(e) {
    this.marvalTarget.innerText = e.target.value;
  }

  showFrontEndVal(e){
    this.frovalTarget.innerText = e.target.value;
  }

  showBackendValue(e){
    this.bacvalTarget.innerText = e.target.value;
  }
}
