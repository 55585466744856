import { Controller } from "@hotwired/stimulus"
import { run } from "./run.js"
import * as program from "./programs/time_milliseconds.js"

// Connects to data-controller="play"
export default class extends Controller {
  static values = {
    colortheme: String
  }

  connect() {
    this.colorTheme = this.colorthemeValue;
   this.fontColor  = this.colorTheme === "dark" ? "white" : "black";
   this.backgroundColor = this.colorTheme === "dark" ? "black" : "white";
  this.render()

  }

  render(){
    // Import the program runner




    // Run settings can override the default- and program seetings.
    // See the API for details.
    const settings = {
      fps: 60,
      element: this.element,
      backgroundColor: this.backgroundColor,
      color: this.fontColor
    }

    // Boot (returns a promise)
    run(program, settings).catch(function (e) {
      console.warn(e.message)
      console.log(e.error)
    })
  }
}
