export class Pixler {

  imageSrc;
  // canvas image
  img;
  // the image ratio
  imgRatio;
  // canvas context
  ctx;
  // The pixelation factor values determine the level of
  // pixelation at each step of the effect.
  // To make the effect more prominent, we start with
  // smaller values initially to keep the big blocks
  // visible for a longer time.
  // Towards the end we don't add many values as
  // we want the sharpening up to happen quickly here.
  pxFactorValues = [1, 2, 4, 9, 100];
  pxIndex = 0;

  constructor(domElement) {
    this.domElement = domElement
    this.imgSrc = this.domElement.dataset.image
    this.canvas = document.createElement('canvas')
    this.canvas.classList.add("canvaspixelwrapper")
    this.canvas.style.borderRadius = "4px"
    this.domElement.appendChild(this.canvas)
    this.ctx = this.canvas.getContext("2d")
    this.img = new Image()
    this.img.src = this.imgSrc

    this.img.onload = () => {
      const imgWidth = this.img.width;
      const imgHeight = this.img.height;
      this.imgRatio = imgWidth / imgHeight;
      this.setCanvasSize();
      this.render();

    }


  }

  setCanvasSize() {
    this.canvas.width = this.domElement.offsetWidth;
    this.canvas.height = this.domElement.offsetHeight;

  }

  render() {
    const offsetWidth = this.domElement.offsetWidth;
    const offsetHeight = this.domElement.offsetHeight;
    // increase a bit to not have a gap in the end of the image
    // when we have big pizel sizes
    const w = offsetWidth + offsetWidth * 0.02;
    const h = offsetHeight + offsetHeight * 0.02;

    // Calculate the dimensions and position for rendering the image
    // within the canvas based on the image aspect ratio.
    let newWidth = w;
    let newHeight = h;
    let newX = 0;
    let newY = 0;

    // Adjust the dimensions and position if the image
    // aspect ratio is different from the canvas aspect ratio
    if (newWidth / newHeight > this.imgRatio) {
      newHeight = Math.round(w / this.imgRatio);
      // let's keep Y at 0 because we want the pixels to not
      // be cut off at the top. Uncomment if you want the
      // image to be centered.
      newY = (h - newHeight) / 2;
    } else {
      newWidth = Math.round(h * this.imgRatio);
      newX = (w - newWidth) / 2;
    }

    // Get the pixel factor based on the current index
    let pxFactor = this.pxFactorValues[this.pxIndex];
    const size = pxFactor * 0.03;

    // Turn off image smoothing to achieve the pixelated effect
    this.ctx.mozImageSmoothingEnabled = size === 1 ? true : false;
    this.ctx.webkitImageSmoothingEnabled = size === 1 ? true : false;
    this.ctx.imageSmoothingEnabled = size === 1 ? true : false;

    // Clear the canvas
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    // Draw the original image at a fraction of the final size
    this.ctx.drawImage(this.img, 0, 0, w * size, h * size);

    // Enlarge the minimized image to full size
    this.ctx.drawImage(
      this.canvas,
      0,
      0,
      w * size,
      h * size,
      newX,
      newY,
      newWidth,
      newHeight
    );
  }

  animatePixels() {
    if (this.pxIndex < this.pxFactorValues.length) {
      // Increase the pixelation factor and continue animating
      setTimeout(() => {
        // Render the image with the current pixelation factor
        this.render();
        this.pxIndex++;
        this.animatePixels();
      }, this.pxIndex === 0 ? 300 : 80); // The first time should be the longest.
    }
    else {
      this.pxIndex = this.pxFactorValues.length - 1;
    }
  }

  startAnimation() {

    return new Promise((resolve, reject) => {
      this.animatePixels()
      resolve()

    })
  }
}
