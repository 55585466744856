import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="reveal"
export default class extends Controller {

  static targets = ["h2" ]

  connect() {
    this.words = [...this.h2Targets];



    this.words.forEach(element => {
        let htmlString = ""
        let wordArray = element.textContent.split("");

        for(let i = 0; i < wordArray.length; i++){
          htmlString += `<span class="spanner">${wordArray[i]}</span>`
        }
        this.h2Target.innerHTML = htmlString
    });

  }

  revealFont(){
    let spans = [...document.querySelectorAll(".spanner")]
    for(let i = 0; i < spans.length; i++){
      if(spans[i].parentElement.getBoundingClientRect().top < window.innerHeight / 2){
        let {left, top} = spans[i].getBoundingClientRect();
        top = top - (window.innerHeight * .4)
        let opacityValue = 1 - ((top * 0.1) + (left * 0.001)) < 0.1 ? 0.1 : 1 - ((top * 0.1) + (left * 0.001)).toFixed(3);
        opacityValue  = opacityValue > 1 ? 1 : opacityValue.toFixed(3);
        spans[i].style.opacity = opacityValue;
      }
    }
  }
}
