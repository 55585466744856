import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="music"
export default class extends Controller {

  static values = {
    sound: String,
    image: String,
  }

  static targets = ["image", "wave"]

  connect() {

    this.music = new Audio(this.soundValue)
  }

  onHover(e){

    document.body.style.cursor = `url('${this.imageValue}'), auto`;
    // document.body.style.cursor = "none"

    this.music.play().catch(console.log)
    // this.imageTarget.classList.remove("d-none")
    // this.imageTarget.src = this.imageValue
  }

  onLeave(e){
    document.body.style.cursor = ""
    this.music.pause();
  }


}
