import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="theme"
export default class extends Controller {

  static targets = ["themebutton"]

  connect() {

  }

  themer(e){

    var value = e.currentTarget.scrollY * 0.25;
    this.themebuttonTarget.style.transform = `translatex(-50%) translatey(-50%) rotate(${value}deg)`;
    this.themebuttonTarget.style.webkitTransform = `translatex(-50%) translatey(-50%) rotate(${value}deg)`;
    this.themebuttonTarget.style.MozTransform = `translatex(-50%) translatey(-50%) rotate(${value}deg)`;
  }
}
