import { Controller } from "@hotwired/stimulus"
import ScrollReveal from "scrollreveal";

// Connects to data-controller="homefade"
export default class extends Controller {
  connect() {
    this.reveals()
  }


  reveals() {
    ScrollReveal().reveal(".s3", { delay: 400, easing: 'ease-in' });
    ScrollReveal().reveal(".s4", { delay: 500, easing: 'ease-in' });
    ScrollReveal().reveal(".s5", { delay: 600, easing: 'ease-in' });
    ScrollReveal().reveal(".s6", { delay: 700, easing: 'ease-in' });
    ScrollReveal().reveal(".s7", { delay: 800, easing: 'ease-in' });
    ScrollReveal().reveal(".s8", { delay: 900, easing: 'ease-in' });
    ScrollReveal().reveal(".s9", { delay: 1000, easing: 'ease-in' });
    ScrollReveal().reveal(".xy", { delay: 1100, easing: 'ease-in' });
    ScrollReveal().reveal(".xyx", { delay: 1100, easing: 'ease-in' });

    ScrollReveal().reveal(".s31", { delay: 1200, easing: 'ease-in' });
    ScrollReveal().reveal(".s32", { delay: 1300, easing: 'ease-in' });
    ScrollReveal().reveal(".s33", { delay: 1200, easing: 'ease-in' });
    ScrollReveal().reveal(".s34", { delay: 1300, easing: 'ease-in' });
    ScrollReveal().reveal(".s35", { delay: 1200, easing: 'ease-in' });
    ScrollReveal().reveal(".s36", { delay: 1200, easing: 'ease-in' });
  }

}
