import { Controller } from "@hotwired/stimulus"
import { useIntersection } from "stimulus-use"
import { Pixler } from "./pixler"


// Connects to data-controller="project-show-pixel"
export default class extends Controller {
  connect() {
    this.options = {
      threshold: 0.8
    }

    if (window.matchMedia("(max-width: 767px)")) {
      this.options.threshold = 0.3
    }

    this.pixi = new Pixler(this.element)
    const [_observe, unobserve] = useIntersection(this, this.options)
    this.unobserve = unobserve
  }

  appear(entry, observer){
    if (entry.isIntersecting) {
      setTimeout(() => {
        this.pixi.startAnimation().then(() => {
          setTimeout(() => {
            this.element.childNodes[0].style.display = "none"
          }, 300);
        })
      }, 2000);
    }

    this.unobserve()
  }
}
